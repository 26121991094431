import {Component} from "vue-property-decorator"
import {VNode} from "vue"
import FormMixin from "@/mixins/FormMixin"


import * as AdminRoutes from "@/_modules/admin/router/admin-routes"

import {appStore} from "@/store"

import {Language as Lang} from "@/types"

const languages: LocaleMenu[] = [
  {
    code: Lang.EST,
    title: 'EST'
  },
  {
    code: Lang.ENG,
    title: 'ENG'
  },
  {
    code: Lang.RUS,
    title: 'RUS'
  }  
]

interface LocaleMenu {
  code: Lang;
  title: string;
}

interface Menu {
  name: string;
  title: string;
}

@Component({name: 'AdminPanel'})
export default class AdminPanel extends FormMixin {
  
  public get menu(): Menu[] {
    return [
      {
        name: AdminRoutes.UserProfileManagement.name,
        title: this.translation('adm.menu_tab_user_profile_management')
      },
      {
        name: AdminRoutes.Agreements.name,
        title: this.translation('adm.menu_tab_agreements')
      },
      {
        name: AdminRoutes.AccountingAndReports.name,
        title: this.translation('adm.menu_tab_accounting_and_reports')
      }
      /*,
      {
        name: AdminRoutes.AdminMsgCenter.name,
        title: this.translation('adm.menu_tab_msg_center')
      }*/
    ]
  }

  public onLangClick(e: Event, code: Lang): void {
    e.preventDefault()
    appStore.setLocale(code)
  }

  public get selectedLanguage(): LocaleMenu {
    const lang = appStore.locale
    const res: LocaleMenu | undefined = languages.find(x => x.code === lang)
    if (res === undefined) {
      throw Error(`Illegal language selected: ${res}`)
    }
    return res
  }
  
  public render(): VNode {
    return (
      <b-container>
        <b-row class="my-5">
          <b-col>
            <b-link disabled onclick={() => this.$router.push('/')} class="btn btn-adm-exit">{this.translation('adm.btn_logout')}</b-link>
          </b-col>
          <b-col class="text-center">
            <b-img src={require('../../../assets/images/logo.png')}/>
          </b-col>
          <b-col class="float-right text-right">
            <b-dropdown class="btn-adm-language-select" variant="light" right>
              <template slot="button-content">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" viewBox="0 0 496 512">
                  <path
                    d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm193.2 152h-82.5c-9-44.4-24.1-82.2-43.2-109.1 55 18.2 100.2 57.9 125.7 109.1zM336 256c0 22.9-1.6 44.2-4.3 64H164.3c-2.7-19.8-4.3-41.1-4.3-64s1.6-44.2 4.3-64h167.4c2.7 19.8 4.3 41.1 4.3 64zM248 40c26.9 0 61.4 44.1 78.1 120H169.9C186.6 84.1 221.1 40 248 40zm-67.5 10.9c-19 26.8-34.2 64.6-43.2 109.1H54.8c25.5-51.2 70.7-90.9 125.7-109.1zM32 256c0-22.3 3.4-43.8 9.7-64h90.5c-2.6 20.5-4.2 41.8-4.2 64s1.5 43.5 4.2 64H41.7c-6.3-20.2-9.7-41.7-9.7-64zm22.8 96h82.5c9 44.4 24.1 82.2 43.2 109.1-55-18.2-100.2-57.9-125.7-109.1zM248 472c-26.9 0-61.4-44.1-78.1-120h156.2c-16.7 75.9-51.2 120-78.1 120zm67.5-10.9c19-26.8 34.2-64.6 43.2-109.1h82.5c-25.5 51.2-70.7 90.9-125.7 109.1zM363.8 320c2.6-20.5 4.2-41.8 4.2-64s-1.5-43.5-4.2-64h90.5c6.3 20.2 9.7 41.7 9.7 64s-3.4 43.8-9.7 64h-90.5z"/>
                </svg>
                {this.translation(`enum_language_user_speak_short_${this.selectedLanguage.title.toLocaleLowerCase()}`)}
              </template>
              {languages.map(language => {
                return (
                  <b-dropdown-item onClick={(e: Event) => this.onLangClick(e, language.code)}>{this.translation(`enum_language_user_speak_short_${language.title.toLocaleLowerCase()}`)}</b-dropdown-item>
                )
              })}
            </b-dropdown>
          </b-col>
        </b-row>
        <b-row class="admin-panel my-3">
          <b-col>
            <b-nav justified tabs class="nav-tabs nav-fill justify-content-center">
              {this.menu.map((tab) => {
                return (
                  <b-nav-item exact exact-active-class="active-link" to={{name: tab.name}}>
                    <span>{tab.title}</span>
                  </b-nav-item>
                )
              })
              }
            </b-nav>
            <b-card-body>
              <b-row>
                <b-col>
                  <router-view/>
                </b-col>
              </b-row>
            </b-card-body>
          </b-col>
        </b-row>
      </b-container>
    )
  }
}